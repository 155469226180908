import { Chip, styled } from "@mui/material";

export const StyledChip = styled(Chip)({
  width: "fit-content",
  backgroundColor: "#FFF !important",
  color: "#1C1B1F",
  fontWeight: 500,
  lineHeight: "1.5rem",
  fontSize: "1rem",
  padding: "0.25rem",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 4px 16px",
});
